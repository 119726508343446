/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.sidebar .nav p,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role='remove'],
.animation-transition-general {
  @include transitions($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.bootstrap-switch-label:before,
.caret {
  @include transitions($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded='true']:after,
a[data-toggle='collapse'][aria-expanded='true'] .caret,
.card-collapse .card a[data-toggle='collapse'][aria-expanded='true'] i,
.card-collapse .card a[data-toggle='collapse'].expanded i {
  @include rotate-180();
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: -5px;
  position: absolute;
  top: 30px;
  margin-left: 5px;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

// card user profile page
.card {
  form {
    label + .form-control {
      margin-bottom: 20px;
    }
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 30%);
  transform: translate(0, 30%);
}

code {
  color: $pink;
}
