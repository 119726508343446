@include media-breakpoint-down(md) {
  .navbar .dropdown.show .dropdown-menu {
    position: static !important;
  }
  .navbar:not(.bg-white) {
    .dropdown.show .dropdown-menu .dropdown-item {
      color: $opacity-8;
    }
    .navbar-nav .btn span {
      color: $opacity-8 !important;
    }
  }
  nav.navbar {
    top: 0px;
  }
}

@include media-breakpoint-down(sm) {
  .navbar .navbar-wrapper {
    .navbar-toggle {
      transition: all 1s;
    }
  }
  .navbar .navbar-wrapper {
    .navbar-toggle.toggled {
      left: -20px;
      position: relative;
      right: unset;
      transition: all 1s;
    }
  }
  .rtl {
    .navbar .navbar-wrapper {
      .navbar-toggle.toggled {
        right: -35px;
        position: relative;
        left: unset;
        transition: all 1s;
      }
    }
  }
}
.nav-link:hover {
  color: $info;
}

// ? Hamburger Menu SVG line animation effect
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}

.line {
  fill: none;
  stroke: #fff;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.white-content .line {
  stroke: #222a42;
}

.opened .line {
  stroke: #222a42;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
