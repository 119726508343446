.card {
  font-weight: 400;
  border: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.er-gradient {
  background-image: linear-gradient(120deg, #0098da 0%, #4a105c 100%);
}

.er-header {
  padding: 1rem !important;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  color: #fff;
  text-align: center;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.card .er-header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}

.btn-social {
  background-color: #fff;
  padding: 0.5rem 2rem;
  font-size: 0.81rem;
}

.btn-social,
.btn-login {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

// Poravnanje check i forget password u liniju
label[for='formLoginRememberMe'],
.er-pt-175 {
  padding-top: 0.175rem;
}

// Poravnanje NavLinka i texta ispred
.er-lineheight {
  line-height: 19px;
}

/* Fix za invalid confirm kod provjere confirmed passworda i passworda jer required je uvjek postavljao OK */
.was-validated .er-invalid.form-control:valid,
.er-invalid.form-control.is-valid {
  border-color: #ff8d72;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff8d72' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff8d72' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.er-valid.valid-feedback {
  display: none !important;
}

// Material form
.er-form {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.er-form input:not([type]),
.er-form input[type='text']:not(.browser-default),
.er-form input[type='password']:not(.browser-default),
.er-form input[type='email']:not(.browser-default),
.er-form input[type='url']:not(.browser-default),
.er-form input[type='time']:not(.browser-default),
.er-form input[type='date']:not(.browser-default),
.er-form input[type='datetime']:not(.browser-default),
.er-form input[type='datetime-local']:not(.browser-default),
.er-form input[type='tel']:not(.browser-default),
.er-form input[type='number']:not(.browser-default),
.er-form input[type='search']:not(.browser-default),
.er-form input[type='phone']:not(.browser-default),
.er-form input[type='search-md'],
.er-form textarea.md-textarea {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.er-form input:not([type]):focus:not([readonly]),
.er-form input[type='text']:not(.browser-default):focus:not([readonly]),
.er-form input[type='password']:not(.browser-default):focus:not([readonly]),
.er-form input[type='email']:not(.browser-default):focus:not([readonly]),
.er-form input[type='url']:not(.browser-default):focus:not([readonly]),
.er-form input[type='time']:not(.browser-default):focus:not([readonly]),
.er-form input[type='date']:not(.browser-default):focus:not([readonly]),
.er-form input[type='datetime']:not(.browser-default):focus:not([readonly]),
.er-form input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
.er-form input[type='tel']:not(.browser-default):focus:not([readonly]),
.er-form input[type='number']:not(.browser-default):focus:not([readonly]),
.er-form input[type='search']:not(.browser-default):focus:not([readonly]),
.er-form input[type='phone']:not(.browser-default):focus:not([readonly]),
.er-form input[type='search-md']:focus:not([readonly]),
.er-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #4285f4;
  -webkit-box-shadow: 0 1px 0 0 #4285f4;
  box-shadow: 0 1px 0 0 #4285f4;
}

.er-form input:not([type]):focus:not([readonly]) + label,
.er-form input[type='text']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='password']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='email']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='url']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='time']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='date']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='number']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='search']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='phone']:not(.browser-default):focus:not([readonly]) + label,
.er-form input[type='search-md']:focus:not([readonly]) + label,
.er-form textarea.md-textarea:focus:not([readonly]) + label {
  color: #4285f4;
}

.er-form input:not([type]) + label::after,
.er-form input[type='text']:not(.browser-default) + label::after,
.er-form input[type='password']:not(.browser-default) + label::after,
.er-form input[type='email']:not(.browser-default) + label::after,
.er-form input[type='url']:not(.browser-default) + label::after,
.er-form input[type='time']:not(.browser-default) + label::after,
.er-form input[type='date']:not(.browser-default) + label::after,
.er-form input[type='datetime']:not(.browser-default) + label::after,
.er-form input[type='datetime-local']:not(.browser-default) + label::after,
.er-form input[type='tel']:not(.browser-default) + label::after,
.er-form input[type='number']:not(.browser-default) + label::after,
.er-form input[type='search']:not(.browser-default) + label::after,
.er-form input[type='phone']:not(.browser-default) + label::after,
.er-form input[type='search-md'] + label::after,
.er-form textarea.md-textarea + label::after {
  position: absolute;
  top: 65px;
  display: block;
  content: '';
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.er-form input:not([type]).valid,
.er-form input:not([type]):focus.valid,
.er-form input[type='text']:not(.browser-default).valid,
.er-form input[type='text']:not(.browser-default):focus.valid,
.er-form input[type='password']:not(.browser-default).valid,
.er-form input[type='password']:not(.browser-default):focus.valid,
.er-form input[type='email']:not(.browser-default).valid,
.er-form input[type='email']:not(.browser-default):focus.valid,
.er-form input[type='url']:not(.browser-default).valid,
.er-form input[type='url']:not(.browser-default):focus.valid,
.er-form input[type='time']:not(.browser-default).valid,
.er-form input[type='time']:not(.browser-default):focus.valid,
.er-form input[type='date']:not(.browser-default).valid,
.er-form input[type='date']:not(.browser-default):focus.valid,
.er-form input[type='datetime']:not(.browser-default).valid,
.er-form input[type='datetime']:not(.browser-default):focus.valid,
.er-form input[type='datetime-local']:not(.browser-default).valid,
.er-form input[type='datetime-local']:not(.browser-default):focus.valid,
.er-form input[type='tel']:not(.browser-default).valid,
.er-form input[type='tel']:not(.browser-default):focus.valid,
.er-form input[type='number']:not(.browser-default).valid,
.er-form input[type='number']:not(.browser-default):focus.valid,
.er-form input[type='search']:not(.browser-default).valid,
.er-form input[type='search']:not(.browser-default):focus.valid,
.er-form input[type='phone']:not(.browser-default).valid,
.er-form input[type='phone']:not(.browser-default):focus.valid,
.er-form input[type='search-md'].valid,
.er-form input[type='search-md']:focus.valid,
.er-form textarea.md-textarea.valid,
.er-form textarea.md-textarea:focus.valid {
  border-bottom: 1px soli#2dce89;
  -webkit-box-shadow: 0 1px 0 0#2dce89;
  box-shadow: 0 1px 0 0#2dce89;
}

.er-form input:not([type]).valid + label:after,
.er-form input:not([type]):focus.valid + label:after,
.er-form input[type='text']:not(.browser-default).valid + label:after,
.er-form input[type='text']:not(.browser-default):focus.valid + label:after,
.er-form input[type='password']:not(.browser-default).valid + label:after,
.er-form input[type='password']:not(.browser-default):focus.valid + label:after,
.er-form input[type='email']:not(.browser-default).valid + label:after,
.er-form input[type='email']:not(.browser-default):focus.valid + label:after,
.er-form input[type='url']:not(.browser-default).valid + label:after,
.er-form input[type='url']:not(.browser-default):focus.valid + label:after,
.er-form input[type='time']:not(.browser-default).valid + label:after,
.er-form input[type='time']:not(.browser-default):focus.valid + label:after,
.er-form input[type='date']:not(.browser-default).valid + label:after,
.er-form input[type='date']:not(.browser-default):focus.valid + label:after,
.er-form input[type='datetime']:not(.browser-default).valid + label:after,
.er-form input[type='datetime']:not(.browser-default):focus.valid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).valid + label:after,
.er-form input[type='datetime-local']:not(.browser-default):focus.valid + label:after,
.er-form input[type='tel']:not(.browser-default).valid + label:after,
.er-form input[type='tel']:not(.browser-default):focus.valid + label:after,
.er-form input[type='number']:not(.browser-default).valid + label:after,
.er-form input[type='number']:not(.browser-default):focus.valid + label:after,
.er-form input[type='search']:not(.browser-default).valid + label:after,
.er-form input[type='search']:not(.browser-default):focus.valid + label:after,
.er-form input[type='phone']:not(.browser-default).valid + label:after,
.er-form input[type='phone']:not(.browser-default):focus.valid + label:after,
.er-form input[type='search-md'].valid + label:after,
.er-form input[type='search-md']:focus.valid + label:after,
.er-form textarea.md-textarea.valid + label:after,
.er-form textarea.md-textarea:focus.valid + label:after {
  color: #2dce89;
  content: attr(data-success);
  opacity: 1;
}

.er-form input:not([type]).invalid,
.er-form input:not([type]):focus.invalid,
.er-form input[type='text']:not(.browser-default).invalid,
.er-form input[type='text']:not(.browser-default):focus.invalid,
.er-form input[type='password']:not(.browser-default).invalid,
.er-form input[type='password']:not(.browser-default):focus.invalid,
.er-form input[type='email']:not(.browser-default).invalid,
.er-form input[type='email']:not(.browser-default):focus.invalid,
.er-form input[type='url']:not(.browser-default).invalid,
.er-form input[type='url']:not(.browser-default):focus.invalid,
.er-form input[type='time']:not(.browser-default).invalid,
.er-form input[type='time']:not(.browser-default):focus.invalid,
.er-form input[type='date']:not(.browser-default).invalid,
.er-form input[type='date']:not(.browser-default):focus.invalid,
.er-form input[type='datetime']:not(.browser-default).invalid,
.er-form input[type='datetime']:not(.browser-default):focus.invalid,
.er-form input[type='datetime-local']:not(.browser-default).invalid,
.er-form input[type='datetime-local']:not(.browser-default):focus.invalid,
.er-form input[type='tel']:not(.browser-default).invalid,
.er-form input[type='tel']:not(.browser-default):focus.invalid,
.er-form input[type='number']:not(.browser-default).invalid,
.er-form input[type='number']:not(.browser-default):focus.invalid,
.er-form input[type='search']:not(.browser-default).invalid,
.er-form input[type='search']:not(.browser-default):focus.invalid,
.er-form input[type='phone']:not(.browser-default).invalid,
.er-form input[type='phone']:not(.browser-default):focus.invalid,
.er-form input[type='search-md'].invalid,
.er-form input[type='search-md']:focus.invalid,
.er-form textarea.md-textarea.invalid,
.er-form textarea.md-textarea:focus.invalid {
  border-bottom: 1px solid #ff8d72;
  -webkit-box-shadow: 0 1px 0 0 #ff8d72;
  box-shadow: 0 1px 0 0 #ff8d72;
}

.er-form input:not([type]).invalid + label:after,
.er-form input:not([type]):focus.invalid + label:after,
.er-form input[type='text']:not(.browser-default).invalid + label:after,
.er-form input[type='text']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='password']:not(.browser-default).invalid + label:after,
.er-form input[type='password']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='email']:not(.browser-default).invalid + label:after,
.er-form input[type='email']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='url']:not(.browser-default).invalid + label:after,
.er-form input[type='url']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='time']:not(.browser-default).invalid + label:after,
.er-form input[type='time']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='date']:not(.browser-default).invalid + label:after,
.er-form input[type='date']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='datetime']:not(.browser-default).invalid + label:after,
.er-form input[type='datetime']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).invalid + label:after,
.er-form input[type='datetime-local']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='tel']:not(.browser-default).invalid + label:after,
.er-form input[type='tel']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='number']:not(.browser-default).invalid + label:after,
.er-form input[type='number']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='search']:not(.browser-default).invalid + label:after,
.er-form input[type='search']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='phone']:not(.browser-default).invalid + label:after,
.er-form input[type='phone']:not(.browser-default):focus.invalid + label:after,
.er-form input[type='search-md'].invalid + label:after,
.er-form input[type='search-md']:focus.invalid + label:after,
.er-form textarea.md-textarea.invalid + label:after,
.er-form textarea.md-textarea:focus.invalid + label:after {
  color: #ff8d72;
  content: attr(data-error);
  opacity: 1;
}

.er-form input:not([type]).form-control.valid + label:after,
.er-form input:not([type]).form-control:focus.valid + label:after,
.er-form input[type='text']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='text']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='password']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='password']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='email']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='email']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='url']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='url']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='time']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='time']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='date']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='date']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='datetime']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='datetime']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='tel']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='tel']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='number']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='number']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='search']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='search']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='phone']:not(.browser-default).form-control.valid + label:after,
.er-form input[type='phone']:not(.browser-default).form-control:focus.valid + label:after,
.er-form input[type='search-md'].form-control.valid + label:after,
.er-form input[type='search-md'].form-control:focus.valid + label:after,
.er-form textarea.md-textarea.form-control.valid + label:after,
.er-form textarea.md-textarea.form-control:focus.valid + label:after {
  top: 4.1rem;
}

.er-form input:not([type]).form-control.invalid + label:after,
.er-form input:not([type]).form-control:focus.invalid + label:after,
.er-form input[type='text']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='text']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='password']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='password']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='email']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='email']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='url']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='url']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='time']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='time']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='date']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='date']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='datetime']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='datetime']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='datetime-local']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='tel']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='tel']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='number']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='number']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='search']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='search']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='phone']:not(.browser-default).form-control.invalid + label:after,
.er-form input[type='phone']:not(.browser-default).form-control:focus.invalid + label:after,
.er-form input[type='search-md'].form-control.invalid + label:after,
.er-form input[type='search-md'].form-control:focus.invalid + label:after,
.er-form textarea.md-textarea.form-control.invalid + label:after,
.er-form textarea.md-textarea.form-control:focus.invalid + label:after {
  top: 4rem;
}

.er-form > input[type='date']:not(.browser-default) + label {
  -webkit-transform: translateY(-27px) scale(0.8);
  transform: translateY(-27px) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.er-form > input[type]:-webkit-autofill:not(.browser-default):not([type='search']) + label,
.er-form > input[type='time']:not(.browser-default) + label {
  font-size: 0.8rem;
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.er-form .was-validated input[type='text']:valid + label {
  color: #2dce89 !important;
}

.er-form .was-validated input[type='text']:invalid + label {
  color: #ff8d72 !important;
}

.er-form .was-validated .form-control:valid:focus {
  -webkit-box-shadow: 0 1px 0 0#2dce89 !important;
  box-shadow: 0 1px 0 0#2dce89 !important;
}

.er-form .was-validated .form-control:valid {
  border-color: #2dce89 !important;
}

.er-form .was-validated .form-control:invalid:focus {
  -webkit-box-shadow: 0 1px 0 0 #ff8d72 !important;
  box-shadow: 0 1px 0 0 #ff8d72 !important;
}

.er-form .was-validated .form-control:invalid {
  border-color: #ff8d72 !important;
}

.er-form .form-control {
  height: auto;
  padding: 0.6rem 0 0.4rem 0;
  margin: 0 0 0.5rem 0;
  background-color: transparent;
  border-radius: 0;
}

.er-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.er-form .form-control:disabled,
.er-form .form-control[readonly] {
  background-color: transparent;
  border-bottom: 1px solid #bdbdbd;
}

.er-form .form-control.is-valid {
  border-color: #2dce89;
}

.er-form .form-control.is-valid:focus {
  border-color: #2dce89 !important;
  -webkit-box-shadow: 0 1px 0 0#2dce89 !important;
  box-shadow: 0 1px 0 0#2dce89 !important;
}

.er-form .form-control.is-invalid {
  border-color: #ff8d72;
}

.er-form .form-control.is-invalid:focus {
  border-color: #ff8d72 !important;
  -webkit-box-shadow: 0 1px 0 0 #ff8d72 !important;
  box-shadow: 0 1px 0 0 #ff8d72 !important;
}

.er-form .form-control.is-valid,
.er-form .form-control.is-invalid {
  background-position: center right !important;
}

.er-form .validate {
  margin-bottom: 2.5rem;
}

.er-form label {
  font-size: 1rem;
}

.er-form label.active {
  font-size: 1rem;
}

.er-form .prefix {
  top: 0.25rem;
  font-size: 1.75rem;
}

.er-form .prefix ~ input,
.er-form .prefix ~ textarea {
  width: calc(100% - 2.5rem);
  margin-left: 2.5rem;
}

.er-form .prefix ~ label {
  margin-left: 2.5rem;
}

.er-form .prefix ~ .form-text {
  margin-left: 2.6rem;
}

.er-form label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  color: #757575;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.er-form label.active,
.er-form.form-group.actives label,
.er-form.form-group.not-empty label {
  -webkit-transform: translateY(-14px) scale(0.8);
  transform: translateY(-14px) scale(0.8);
}

.er-form.form-group small {
  opacity: 0;
}

.er-form.form-group small.active {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.er-form.form-group.not-empty small.false,
.er-form.form-group small.active,
.er-form.form-group.actives small.active,
.er-form.form-group.not-empty.actives small.active {
  opacity: 1;
}

.er-form.form-group .form-control,
.er-form.input-group .form-control {
  padding: 10px 1px 10px 1px !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.invalid-feedback,
.valid-feedback {
  margin-left: 2.5rem;
  margin-right: 0;
  width: auto;
}

.er-form .prefix {
  position: absolute;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.er-form .form-control.is-invalid,
.was-validated .er-form .form-control:invalid {
  padding-right: 0;
}

.er-form .form-control.is-valid,
.was-validated .er-form .form-control:valid {
  padding-right: 0;
}

.needs-validation .er-form label {
  left: 0.3rem;
}

.er-form > label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.er-form .form-control {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

/* Ikonice i tekst za error hendlanje u formama login register... */
.er-input-ml-w,
.er-input-ml-w ~ label {
  margin-left: 2.5rem !important;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
  width: stretch;
}

.er-form .prefix.active {
  color: #4285f4;
}

.er-invalid {
  color: #ff8d72;
}

/* Ovi su samo za kontrolu confirm passworada jer validator ne checkira podudarnost pasworda FIX */
.not-empty svg.er-valid-icons {
  color: #2dce89;
}

.not-empty svg.er-invalid-icons {
  color: #ff8d72;
}

.was-validated .er-invalid.form-control:valid ~ svg,
.er-invalid.form-control.is-valid ~ svg {
  color: #ff8d72;
}

/* Za sve ikonice koje se same checkiraju od strane form validationa */
.was-validated .form-control:valid ~ svg {
  color: #2dce89;
}

.was-validated .form-control:invalid ~ svg {
  color: #ff8d72 !important;
}

.close.btn:focus {
  box-shadow: none;
}
