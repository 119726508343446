@include media-breakpoint-up(xl) {
  .perfect-scrollbar-on {
    .sidebar {
      height: calc(100vh - 100px);
    }
  }
  .footer {
    margin-left: 255px;
  }
}

@include media-breakpoint-down(md) {
  html.nav-open body {
    position: relative;
    overflow-x: hidden;
  }

  html.nav-open .main-panel {
    overflow: auto;
    min-height: unset;
  }

  html.nav-open .wrapper {
    overflow: hidden;
  }
}
