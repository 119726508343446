.white-content {
  .form-group:not(.has-danger):not(.has-success),
  .input-group-focus:not(.has-danger):not(.has-success) {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $info;
    }
    .form-control {
      &:focus {
        border-color: $info;
        & + .input-group-append .input-group-text,
        & ~ .input-group-append .input-group-text,
        & + .input-group-prepend .input-group-text,
        & ~ .input-group-prepend .input-group-text {
          border: 1px solid $info;
        }
      }
    }
  }

  .form-group.has-danger,
  .input-group-focus.has-danger {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $danger;
    }

    .form-control {
      &:focus {
        border-color: $danger;
        & + .input-group-append .input-group-text,
        & ~ .input-group-append .input-group-text,
        & + .input-group-prepend .input-group-text,
        & ~ .input-group-prepend .input-group-text {
          border: 1px solid $danger;
        }
      }
    }
  }
}
