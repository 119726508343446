.floating {
  animation: floating 3s ease infinite;
  will-change: transform;

  &:hover {
    animation-play-state: paused;
  }
}

.floating-lg {
  animation: floating-lg 3s ease infinite;
}

.floating-sm {
  animation: floating-sm 3s ease infinite;
}

// Keyframes
@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
