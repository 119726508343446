/* Scrolbar HTML */
html {
  --scrollbarBG: transparent;
  --thumbBG: #6c757d;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}
